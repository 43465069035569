<template>
  <v-theme-provider dark>
    <base-section
      class="accent"
      space="56"
    >
      <v-container>
        <base-icon
        class="mb-8"
         >
        </base-icon>
        <base-section-heading
         title="살균 소독제"
         :inset="inset"
        >
        </base-section-heading>
        <v-container>
        기구 / 용기 / 포장용 살균소독제
        <v-simple-table
        class="accent"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  구 분
                </th>
                <th class="text-center">
                  품 명
                </th>
                <th class="text-center">
                  식양청 인증번호
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in items"
                :key="item.name"
              >
                <td class="text-center">{{ item.name }}</td>
                <td class="text-center">{{ item.prod }}</td>
                <td
                class="text-center"
                v-html="item.confNum"
                ></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
      </v-container>

    <v-container>
      <base-icon class="mb-8">
      </base-icon>
      <base-section-heading
       title="살균 소독제 장/단점"
       :inset="inset"
      >
   </base-section-heading>
      <v-container>
       1. 기구 / 용기 / 포장용 살균소독제
        <v-simple-table
        class="accent"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  구 분
                </th>
                <th class="text-center">
                  품 명
                </th>
                <th class="text-center">
                  장점
                </th>
                <th class="text-center">
                  단점
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in desserts"
                :key="item.name"
              >
                <td
                class="text-center"
                style="width:20%;"
                >{{ item.name }}</td>
                <td
                  class="text-center"
                  v-html="item.prod"
                  style="width:20%;"
                  ></td>
                <td
                 v-html="item.advtg"
                 style="width:30%;"
                 ></td>
                <td
                 v-html="item.disadvtg"
                 style="width:30%;"
                 ></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
      <v-container>
        2. 식품첨가물 / 혼합제제
        <v-simple-table
        class="accent"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">
                  구 분
                </th>
                <th class="text-center">
                  품 명
                </th>
                <th class="text-center">
                  장점
                </th>
                <th class="text-center">
                  단점
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="foods in foods"
                :key="foods.name"
              >
                <td
                class="text-center"
                style="width:20%;"
                >{{ foods.name }}</td>
                <td
                  class="text-center"
                  v-html="foods.prod"
                  style="width:20%;"
                  ></td>
                <td
                 v-html="foods.advtg"
                 style="width:30%;"
                 ></td>
                <td
                v-html="foods.disadvtg"
                style="width:25%;"
                ></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
        <v-container style="color: coral !important;">
          식품첨가물 / 혼합제제의 경우 노린스에 대한 사용기준이 없으므로 기구, 용기, 포장용 살균소독제의 노린스 사용기준에 준하여 사용하시면 됩니다.<br>
          염소계의 노린스 사용기준은 치아염소나트륨 200ppm, 이염화이소시안눌산나트륨 100ppm 입니다.<br>
          알코올의 경우 제조업체의 사용기준을 준수하십시오.
        </v-container>
      </v-container>

    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    provide: {
      heading: { align: 'center' },
      theme: { isDark: true },
    },

    data: () => ({
      items: [
        {
          name: '염소계',
          prod: 'ACL-1000 (사용방법보기)',
          confNum: '<br>살균한시제2005-48호<br><br>',
        },
        {
          name: '요오드계',
          prod: '새니크린505 (사용방법보기)',
          confNum: '<br>살균한시제2008-040001호<br><br>',
        },
        {
          name: '4급 암모늄',
          prod: '새니크린쿼트 (사용방법보기)',
          confNum: '<br>살균한시제2006-37호<br><br>',
        },
      ],
      desserts: [
        {
          name: '염소계',
          prod: 'ACL-1000 ',
          advtg: '<br>- 광범위한 살균력 작용이 빠름 <br> - 차아염소나트륨에 비해 안전성이 높고 부식성 및 독성이 약하며, pH와 온도 영향이 덜함. <br> - 표백효과가 수반됨.<br><br>',
          disadvtg: '<br>- pH와 온도영향이 다소 있음. <br> - 유기물의 영향이 다소 있음. <br> - 유효염소의 분해가 빨라 살균 지속성이 약함.<br>',
        },
        {
          name: '요오드계',
          prod: '새니크린505 ',
          advtg: '<br>- 광범위하고 빠른 살균력. <br> - 염소계보다 낮은 농도에서 효과발휘. <br> - 세척효과 수반. <br> - 부식성, 피부자극 없음. <br> - 염소보다 살균시속성이 우수함. <br> - 염소보다 유기물 영향 적음.<br><br>',
          disadvtg: '<br>-요오드계의 특성상 희석농도에 따라 플라스틱등에 색변이 발생 할 수 있음. <br> - 40도 이상 온도에서 살균력이 약화 될 수 있음<br>',
        },
        {
          name: '4급 암모늄',
          prod: '새니크린쿼트',
          advtg: '<br>- 그람음성,양성에 모두 작용하는 4급 암모늄염 사용. <br> - 살균지속성이 매우 우수. <br> - 모든 pH 범위에서 효과적. <br> - 온도영향 양호. <br> - 낮은 독성(무색, 무취, 비부식성) <br> - 경수에도 안정.<br><br>',
          disadvtg: '<br>- 4급 암모늄게의 특성상 희석농도에 따라 거품 및 미끄러움이 발생 할 수 있음.<br>',
        },
        {
          name: '과산 화계',
          prod: 'PAC-38',
          advtg: '<br>- 광범위한 살균 효과. <br> - 무기포, 무린성분.<br> - 잔류물을 남기지 않음. <br> - 온도영향 없고, 경수에 안정. <br> - 우수한 생분해성<br><br>',
          disadvtg: '<br>- 냄새가 자극적임. <br> - 원액독성이 있음.<br>',
        },
      ],
      foods: [
        {
          name: '',
          prod: '새니크린 내츄럴 ',
          advtg: '<br>- 무알콜타입의 살균 및 황산화제 <br> - 자몽종자추출물, 유기산등 천연성분. <br> - 빠르고 강력한 살균력. <br><br>',
          disadvtg: '<br>- 비싼단가.<br>',
        },
        {
          name: '염소계',
          prod: 'ACL-60 ',
          advtg: '<br>- 광범위하고 빠른 살균력 작용이 빠르다.<br><br>',
          disadvtg: '<br>- pH와 온도영향이 심함. <br> - 유기물의 영향이 심함. <br> - 유효염소의 분해가 빨라 살균지속성이 급격히 약화됨.<br><br>',
        },

      ],
      drawer: null,
      inset: false,
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },
  }
</script>

<style scoped>
.v-data-table__wrapper > table > thead > tr > th {
    font-size: 18px !important;
    color: coral !important;
}
</style>
